// 초등학교
const CURRICULUM_KEY_초등학교_1학년_1학기 = '1.2.5.12.50'
const CURRICULUM_KEY_초등학교_1학년_2학기 = '1.2.5.12.68'
const CURRICULUM_KEY_초등학교_2학년_1학기 = '1.2.5.14.52'
const CURRICULUM_KEY_초등학교_2학년_2학기 = '1.2.5.14.70'

const CURRICULUM_KEY_초등학교_1학년_1학기_22개정 = '1.4.4145.4148.4157'
const CURRICULUM_KEY_초등학교_1학년_2학기_22개정 = '1.4.4145.4148.4158'
const CURRICULUM_KEY_초등학교_2학년_1학기_22개정 = '1.4.4145.4149.4159'
const CURRICULUM_KEY_초등학교_2학년_2학기_22개정 = '1.4.4145.4149.4160'

// 중학교
const CURRICULUM_KEY_중학교_1학년_2학기_22개정 = '1.4.4146.4154.4170'
const CURRICULUM_KEY_중학교_2학년_1학기_22개정 = '1.4.4146.4155.4171'

// 고등학교
const CURRICULUM_KEY_고등학교_수학_상 = '1.2.7.41'
const CURRICULUM_KEY_고등학교_수학_하 = '1.2.7.42'
const CURRICULUM_KEY_고등학교_수학I = '1.2.7.43'
const CURRICULUM_KEY_고등학교_수학II = '1.2.7.44'

const CURRICULUM_KEY_공통수학1_22개정 = '1.4.4147.4175'
const CURRICULUM_KEY_공통수학2_22개정 = '1.4.4147.4176'
const CURRICULUM_KEY_대수_22개정 = '1.4.4147.4177'
const CURRICULUM_KEY_기하1_22개정 = '1.4.4147.4181'
const CURRICULUM_KEY_고등학교_미적분1_22개정 = '1.4.4147.4178'

// curriculumKey로 관리되지 않는 영역이 존재하기 때문.
export const PRE_OPEN_CURRICULUM_INFO = [
  {
    schoolType: 'MIDDLE',
    grade: '2',
    semster: 1,
    revision: 'CURRICULUM_22',
  },
]
// 1차 오픈 키(= 사전 오픈 = 풀오픈 이전)
export const PRE_OPEN_CURRICULUM_KEYS = [CURRICULUM_KEY_고등학교_미적분1_22개정]
// CURRICULUM_ID는 CURRICULUM_KEY를 .으로 구분했을 때의 마지막 Number 값
export const PRE_OPEN_CURRICULUM_IDS = PRE_OPEN_CURRICULUM_KEYS.map((key) => {
  const splitKeys = key.split('.')
  return Number(splitKeys[splitKeys.length - 1])
})

export const CURRICULUM_KEYS = {
  CURRICULUM_KEY_초등학교_1학년_1학기,
  CURRICULUM_KEY_초등학교_1학년_2학기,
  CURRICULUM_KEY_초등학교_2학년_1학기,
  CURRICULUM_KEY_초등학교_2학년_2학기,
  CURRICULUM_KEY_초등학교_1학년_1학기_22개정,
  CURRICULUM_KEY_초등학교_1학년_2학기_22개정,
  CURRICULUM_KEY_초등학교_2학년_1학기_22개정,
  CURRICULUM_KEY_초등학교_2학년_2학기_22개정,

  CURRICULUM_KEY_중학교_1학년_2학기_22개정,
  CURRICULUM_KEY_중학교_2학년_1학기_22개정,

  CURRICULUM_KEY_고등학교_수학_상,
  CURRICULUM_KEY_고등학교_수학_하,
  CURRICULUM_KEY_고등학교_수학I,
  CURRICULUM_KEY_고등학교_수학II,
  CURRICULUM_KEY_공통수학1_22개정,
  CURRICULUM_KEY_공통수학2_22개정,
  CURRICULUM_KEY_대수_22개정,
  CURRICULUM_KEY_기하1_22개정,
  CURRICULUM_KEY_고등학교_미적분1_22개정,
}
