import type { ISODate } from '@mathflat/shared/@types/date'
import type { ZeroValue } from '@mathflat/shared/@types/number'
import type { EmptyString } from '@mathflat/shared/@types/string'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import { format } from 'date-fns'

import type { CurriculumDomain } from '~/@entities'
import { WorkbookDomain } from '~/@entities/(Content)/Workbook/domain.ts'
import type { AcademyDomain } from '~/@entities/Academy/module.ts'
import type { DesignTemplateResponse } from '~/@entities/DesignTemplate/api'
import {
  type EditableImageCoverDataItem,
  type TextCoverDataItem,
  ToLocaleStringCoverDataItem,
} from '~/@entities/DesignTemplate/CoverData/dto'
import { CoverData } from '~/@entities/DesignTemplate/CoverData/dto'
import type { DesignTemplate } from '~/@entities/DesignTemplate/dto'
import type { Entity as OrderEntity } from '~/@entities/PODMall/Order/dto'
import type { Entity as ProductEntity } from '~/@entities/PODMall/Product/dto'
import type { Entity as ProductDetailEntity } from '~/@entities/PODMall/ProductDetail/dto'
import type { AllGradeSubject, SchoolType, Semester } from '~/@entities/SchoolSystem/schema.ts'
import { SchoolSystem } from '~/@entities/SchoolSystem/SchoolSystem'
import type { TeacherSchema } from '~/@entities/Teacher/schema'

import type { ContentLevel, PARTITION_TYPE } from '../module'
import type { WorkbookApi } from './api'

// private type으로 접근하지 마세요
type _WorkbookType = ValueOf<typeof WorkbookDomain.TYPE>
type _ZeroPage = ZeroValue | null | undefined
type _CurriculumConceptType = ValueOf<typeof CurriculumDomain.CURRICULUM_CONCEPT_TYPE>
type _SignatureType = WorkbookDomain.SignatureType
type _CommonWorkbookGetters = '한글학년학기과목' | '한글학년학기과목개정' | 'uiWorkbookTitle'

class _WorkbookCommon {
  id: number
  type: _WorkbookType
  level: ContentLevel
  page: number | null | undefined
  schoolType: SchoolType
  grade: AllGradeSubject
  semester: Semester
  title: string
  subtitle: string
  fulltitle: string
  assignmentFlag: boolean // 출제가능여부 (출제 되었다가 아님)
  autoScored: boolean
  coverType: number // TODO: 리터럴일 수 있음.
  partitionType: keyof typeof PARTITION_TYPE
  conceptFlag: boolean
  createDatetime: Date
  revision: 'CURRICULUM_15' | 'CURRICULUM_22' // 개정 - 교육과정 15, 22
  thumbnailImageUrl: string

  constructor(
    params: Omit<
      _WorkbookCommon,
      'createDatetime' | 'formattedCreateDatetime' | _CommonWorkbookGetters
    > & {
      createDatetime: ISODate | Date
    },
  ) {
    this.id = params.id
    this.type = params.type
    this.level = params.level
    this.page = params.page
    this.schoolType = params.schoolType
    this.grade = params.grade
    this.semester = params.semester
    this.title = params.title
    this.subtitle = params.subtitle
    this.fulltitle = params.fulltitle
    this.assignmentFlag = params.assignmentFlag
    this.autoScored = params.autoScored
    this.coverType = params.coverType
    this.partitionType = params.partitionType
    this.conceptFlag = params.conceptFlag
    this.createDatetime = new Date(params.createDatetime)
    this.revision = params.revision
    this.thumbnailImageUrl = params.thumbnailImageUrl
  }

  static getKoreanGradeSemesterSubject({
    grade,
    semester,
    schoolType,
    revision,
    workbookType,
  }: {
    grade: AllGradeSubject
    semester: Semester
    schoolType: SchoolType
    workbookType: _WorkbookType
    revision?: _WorkbookCommon['revision']
  }) {
    const { koSchoolType, koSubject } = SchoolSystem.getKoreanCommonGradeSemester({
      grade,
      semester,
      schoolType,
    })
    if (schoolType === 'MIDDLE' && workbookType === 'SCHOOL') {
      return `${koSchoolType}${grade}`
    }

    if (koSchoolType === '고') {
      return koSubject
    }
    return revision
      ? `${koSchoolType} ${grade}-${semester}`
      : `${koSchoolType} ${grade}-${semester} ${revision === 'CURRICULUM_22' ? '(22개정)' : ''}`
  }

  get 한글학년학기과목() {
    return _WorkbookCommon.getKoreanGradeSemesterSubject({
      grade: this.grade,
      semester: this.semester,
      schoolType: this.schoolType,
      workbookType: this.type,
    })
  }

  get 한글학년학기과목개정() {
    return _WorkbookCommon.getKoreanGradeSemesterSubject({
      grade: this.grade,
      semester: this.semester,
      schoolType: this.schoolType,
      workbookType: this.type,
      revision: this.revision,
    })
  }

  get uiWorkbookTitle() {
    return `${this.title} ${this.subtitle ? `- ${this.subtitle}` : ''}`
  }

  get formattedCreateDatetime() {
    return format(this.createDatetime, 'yy.MM.dd')
  }
}

export namespace Entity {
  export type CustomWorkbookCoverDataT = CustomSignatureWorkbookCoverDataT

  export class CustomWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.내교재>
    headtitle: string
    edition: EmptyString
    publisher: EmptyString
    pdfUrl: string
    answerPdfUrl: string
    minPage: number
    maxPage: number
    answerPage: number
    curriculumConceptType: _CurriculumConceptType
    academyId: AcademyDomain.Id
    teacherId: TeacherSchema.Id
    assigned: boolean | null // 출제되었는지 (withAssigned Params를 보내야함) TODO: deprecated
    coverData: CoverData<CustomWorkbookCoverDataT> | null = null
    innerDesignTemplateId: number = 1
    designTemplateId: DesignTemplate['id'] | null
    productId: ProductEntity.Product['id']
    thumbnailImageKey: string
    problemColorPrice: ProductDetailEntity.ProductDetail['problemAnswerColorPrice']
    problemBwPrice: ProductDetailEntity.ProductDetail['problemAnswerBwPrice']
    problemAnswerBwPrice: ProductDetailEntity.ProductDetail['problemAnswerBwPrice']
    problemAnswerColorPrice: ProductDetailEntity.ProductDetail['problemAnswerColorPrice']
    orderDatetime: OrderEntity.DirectOrder['orderDatetime'] | null
    pdfStatus: 'FAIL' | 'SUCCESS' | 'GENERATING'

    constructor(
      params:
        | (Omit<CustomWorkbook, 'createDatetime' | _CommonWorkbookGetters> & {
            createDatetime: ISODate | Date
            orderDatetime: ISODate | Date | null
          })
        | (Omit<CustomWorkbook, 'createDatetime' | _CommonWorkbookGetters> & {
            createDatetime: ISODate | Date
            orderDatetime: ISODate | Date | null
            coverData: DesignTemplateResponse<CustomWorkbookCoverDataT>['coverData'] | null
          }),
    ) {
      super(params)
      this.type = params.type
      this.headtitle = params.headtitle
      this.subtitle = params.subtitle
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.curriculumConceptType = params.curriculumConceptType
      this.academyId = params.academyId
      this.teacherId = params.teacherId
      this.assigned = params.assigned // TODO: deprecated
      this.innerDesignTemplateId = params.innerDesignTemplateId
      this.designTemplateId = params.designTemplateId
      this.productId = params.productId
      this.thumbnailImageKey = params.thumbnailImageKey
      this.problemColorPrice = params.problemColorPrice
      this.problemBwPrice = params.problemBwPrice
      this.problemAnswerBwPrice = params.problemAnswerBwPrice
      this.problemAnswerColorPrice = params.problemAnswerColorPrice
      this.orderDatetime = params.orderDatetime
      this.pdfStatus = params.pdfStatus

      if (params.coverData) {
        this.coverData = new CoverData(params.coverData)

        // 교재 가격 배포 전 생성된 교재들에 대해 가격정보가 없는 경우 default 값을 설정. (임시코드)
        if (this.coverData && !this.coverData.preAssignedCoverData.salesPrice) {
          this.coverData.preAssignedCoverData.salesPrice =
            new ToLocaleStringCoverDataItem<'salesPrice'>(tempSalesPriceConstructorParams)
        }
      }
    }
  }

  export class PublicWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.시중교재>
    override page: _ZeroPage
    headtitle: EmptyString
    edition: EmptyString
    publisher: string
    pdfUrl: null
    answerPdfUrl: null
    minPage: _ZeroPage
    maxPage: _ZeroPage
    answerPage: _ZeroPage
    pairX: 0 | 1 | 2 | 3 | null
    pairFlag: boolean

    constructor(
      params: Omit<PublicWorkbook, 'createDatetime' | _CommonWorkbookGetters> & {
        createDatetime: ISODate | Date
      },
    ) {
      super(params)
      this.type = params.type
      this.page = params.page
      this.headtitle = params.headtitle
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.pairX = params.pairX
      this.pairFlag = params.pairFlag
    }
  }

  export class SchoolWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.교과서>
    override page: _ZeroPage
    headtitle: EmptyString
    edition: string
    publisher: string
    pdfUrl: null
    answerPdfUrl: null
    minPage: _ZeroPage
    maxPage: _ZeroPage
    answerPage: _ZeroPage
    teacherId: TeacherSchema.Id
    pairX: 0 | 1 | 2 | 3 | null
    pairFlag: boolean
    assigned: boolean | null // TODO: deprecated
    constructor(
      params: Omit<SchoolWorkbook, 'createDatetime' | _CommonWorkbookGetters> & {
        createDatetime: ISODate | Date
      },
    ) {
      super(params)
      this.type = params.type
      this.page = params.page
      this.headtitle = params.headtitle
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.teacherId = params.teacherId
      this.pairX = params.pairX
      this.pairFlag = params.pairFlag
      this.assigned = params.assigned // TODO: deprecated
    }
  }

  export class SignatureWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.시그니처교재>
    override page: _ZeroPage
    headtitle: string
    featureType: WorkbookDomain.CustomSignatureFeatureType
    edition: EmptyString
    publisher: string
    pdfUrl: string
    answerPdfUrl: string
    minPage: _ZeroPage
    maxPage: _ZeroPage
    answerPage: _ZeroPage
    pairX: 0 | 1 | 2 | 3 | null
    pairFlag: boolean
    signatureType: _SignatureType
    signatureWorkbookFulltitle: string
    productId: number

    constructor(
      params: Omit<SignatureWorkbook, 'createDatetime' | _CommonWorkbookGetters> & {
        createDatetime: ISODate | Date
      },
    ) {
      super(params)
      this.page = params.page
      this.type = params.type
      this.headtitle = params.headtitle
      this.featureType = params.featureType
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.pairX = params.pairX
      this.pairFlag = params.pairFlag
      this.signatureType = params.signatureType
      this.productId = params.productId
      this.signatureWorkbookFulltitle = params.signatureWorkbookFulltitle
    }
  }

  type _CustomSignatureWorkbookCoverDataT = {
    headtitle: TextCoverDataItem<'headtitle'> // 대제목이 아니라 학년/학기/과목 이라는 충격적 사실
    subtitle: TextCoverDataItem<'subtitle'> | null
    title: TextCoverDataItem<'title'> // 대제목
    academyName: TextCoverDataItem<'academyName'> | null
    studentName: TextCoverDataItem<'studentName'>
    sideTitle: TextCoverDataItem<'sideTitle'> // value가 empty일 수 있음
    backTopText: TextCoverDataItem<'backTopText'>
    backBottomText: TextCoverDataItem<'backBottomText'>
    frontLogoImage: EditableImageCoverDataItem | null
    backLogoImage: EditableImageCoverDataItem | null
    salesPrice: ToLocaleStringCoverDataItem<'salesPrice'>
  }

  export type CustomSignatureWorkbookCoverDataT = _CustomSignatureWorkbookCoverDataT

  const tempSalesPriceConstructorParams: ConstructorParameters<
    typeof ToLocaleStringCoverDataItem<'salesPrice'>
  >[0] = {
    type: 'TO_LOCALE_STRING',
    props: {
      readOnly: true,
      prefixValue: '정가: ',
      renderProps: {
        x: 2,
        y: 767,
        height: 12,
        fontSize: 10,
        maxWidth: 100,
        position: 'bottom-right',
        fontColor: '#111',
        fontFamily: 'Pretendard-Medium',
        transform: 'rotate(90 52 773)',
      },
      suffixValue: '원',
      defaultValue: 10000,
    },
    id: 'salesPrice',
    order: 10,
  }
  export class CustomSignatureWorkbook extends _WorkbookCommon {
    override type: Extract<_WorkbookType, typeof WorkbookDomain.TYPE.커스텀시그니처교재>
    override page: _ZeroPage
    headtitle: string
    featureType: WorkbookDomain.CustomSignatureFeatureType
    edition: EmptyString
    publisher: string
    pdfUrl: string
    answerPdfUrl: string
    minPage: _ZeroPage
    maxPage: _ZeroPage
    answerPage: _ZeroPage
    pairX: 0 | 1 | 2 | 3 | null
    pairFlag: boolean
    signatureType: _SignatureType
    thumbnailImageKey: string | null

    productId: number
    productStatus: 'ACTIVE' | 'INACTIVE'
    designTemplateId: DesignTemplate['id'] | null
    academyName: null
    solutionPdfUrl: string
    academyId: AcademyDomain.Id
    signatureWorkbookFulltitle: string
    signatureWorkbookId: number
    coverData: CoverData<CustomSignatureWorkbookCoverDataT> | null = null

    orderDatetime: Date | null
    orderStatus: 'PAYMENT_COMPLETED' | 'IN_PRODUCTION' | 'DELIVERY_COMPLETED' | 'ORDER_CANCEL'

    constructor({
      coverData,
      ...params
    }:
      | (Omit<
          CustomSignatureWorkbook,
          'createDatetime' | 'orderDatetime' | 'formattedCreateDatetime' | _CommonWorkbookGetters
        > & {
          createDatetime: ISODate | Date
          orderDatetime?: ISODate | Date | null
        })
      | (Omit<
          CustomSignatureWorkbook,
          | 'createDatetime'
          | 'orderDatetime'
          | 'coverData'
          | 'formattedCreateDatetime'
          | _CommonWorkbookGetters
        > & {
          createDatetime: ISODate | Date
          orderDatetime?: ISODate | Date | null
          coverData: DesignTemplateResponse<CustomSignatureWorkbookCoverDataT>['coverData'] | null
        })) {
      super(params)
      this.page = params.page
      this.type = params.type
      this.headtitle = params.headtitle
      this.featureType = params.featureType
      this.edition = params.edition
      this.publisher = params.publisher
      this.pdfUrl = params.pdfUrl
      this.answerPdfUrl = params.answerPdfUrl
      this.minPage = params.minPage
      this.maxPage = params.maxPage
      this.answerPage = params.answerPage
      this.pairX = params.pairX
      this.pairFlag = params.pairFlag
      this.signatureType = params.signatureType
      this.thumbnailImageKey = params.thumbnailImageKey
      this.productId = params.productId
      this.designTemplateId = params.designTemplateId
      this.academyName = params.academyName
      this.solutionPdfUrl = params.solutionPdfUrl
      this.academyId = params.academyId
      this.signatureWorkbookFulltitle = params.signatureWorkbookFulltitle
      this.signatureWorkbookId = params.signatureWorkbookId
      this.orderDatetime = params.orderDatetime ? new Date(params.orderDatetime) : null
      this.orderStatus = params.orderStatus
      this.productStatus = params.productStatus
      this.coverData = coverData
        ? new CoverData<CustomSignatureWorkbookCoverDataT>(coverData)
        : null

      // 교재 가격 배포 전 생성된 교재들에 대해 가격정보가 없는 경우 default 값을 설정. (임시코드)
      if (this.coverData && !this.coverData.preAssignedCoverData.salesPrice) {
        this.coverData.preAssignedCoverData.salesPrice =
          new ToLocaleStringCoverDataItem<'salesPrice'>(tempSalesPriceConstructorParams)
      }
    }
  }

  // * Workbook *
  export type Workbook<T extends WorkbookDomain.Type = WorkbookDomain.Type> =
    T extends WorkbookDomain.TYPE['교과서']
      ? SchoolWorkbook
      : T extends WorkbookDomain.TYPE['시중교재']
        ? PublicWorkbook
        : T extends WorkbookDomain.TYPE['내교재']
          ? CustomWorkbook
          : T extends WorkbookDomain.TYPE['시그니처교재']
            ? SignatureWorkbook
            : T extends WorkbookDomain.TYPE['커스텀시그니처교재']
              ? CustomSignatureWorkbook
              : never

  export type SupportedWorkbookType = Extract<
    WorkbookDomain.Type,
    | WorkbookDomain.TYPE['커스텀시그니처교재']
    | WorkbookDomain.TYPE['시중교재']
    | WorkbookDomain.TYPE['교과서']
  >

  export type SupportedWorkbook<T extends SupportedWorkbookType = SupportedWorkbookType> =
    T extends WorkbookDomain.TYPE['교과서']
      ? SchoolWorkbook
      : T extends WorkbookDomain.TYPE['시중교재']
        ? PublicWorkbook
        : T extends WorkbookDomain.TYPE['커스텀시그니처교재']
          ? CustomSignatureWorkbook
          : never

  export function createWorkbook<T extends WorkbookDomain.Type>(
    workbook: Workbook<T>,
  ): Workbook<T> {
    switch (workbook.type) {
      case WorkbookDomain.TYPE.교과서:
        return new SchoolWorkbook(workbook) as Workbook<T>
      case WorkbookDomain.TYPE.시중교재:
        return new PublicWorkbook(workbook) as Workbook<T>
      case WorkbookDomain.TYPE.내교재:
        return new CustomWorkbook(workbook) as Workbook<T>
      case WorkbookDomain.TYPE.시그니처교재:
        return new SignatureWorkbook(workbook) as Workbook<T>
      case WorkbookDomain.TYPE.커스텀시그니처교재:
        return new CustomSignatureWorkbook(workbook) as Workbook<T>
    }
  }

  export class WorkbookResponse<T extends WorkbookDomain.Type = WorkbookDomain.Type> {
    workbook: Workbook<T>
    assigned: boolean | null
    lastAssignDateTime: Date | null
    lastUsePageNumber: number | null
    list: WorkbookDomain.AssignedStudent[]

    constructor({
      assigned,
      lastAssignDateTime,
      lastUsePageNumber,
      list,
      ...params
    }: WorkbookApi.Response.GetWorkbook<T> | Omit<WorkbookResponse<T>, 'id'>) {
      this.workbook =
        'workbook' in params ? params.workbook : createWorkbook(params as unknown as Workbook<T>)
      this.assigned = assigned
      this.lastAssignDateTime = lastAssignDateTime ? new Date(lastAssignDateTime) : null
      this.lastUsePageNumber = lastUsePageNumber
      this.list = list
    }

    get id() {
      return this.workbook.id
    }
  }
}
