/**
 *  @desc key가 raw 값, value가 ui값
 */
export const 내신대비교과서 = {
  교학사: '교학사',
  금성출판사: '금성',
  '동아출판(강)': '동아(강)',
  '동아출판(박)': '동아(박)',
  미래엔: '미래엔',
  비상교육: '비상교육',
  좋은책신사고: '좋은책 신사고',
  지학사: '지학사',
  '천재교육(류)': '천재교육(류)',
  '천재교육(이)': '천재교육(이)',

  // 22개정 교과서
  비상: '비상',
  동아출판: '동아',
  와이비엠: '와이비엠',
  능률: '능률',
  '천재(김화경)': '천재(김화경)',
  '천재(김동재)': '천재(김동재)',
  '천재교육(전)': '천재교육(전)',
  '천재교육(홍)': '천재교육(홍)',
} as const

export const LEVEL_KOR = {
  1: '하',
  2: '중하',
  3: '중',
  4: '중상',
  5: '상',
} as const

export type Level = keyof typeof LEVEL_KOR

// 선생님앱이랑 학생앱 레벨 차이가 있나봄..? TODO: 확인하기
export const LEVEL_KOR_TEACHER = {
  1: '하',
  2: '중하',
  3: '중',
  4: '상',
  5: '최상',
} as const

export type SimilarLevel = 'EASIER' | 'AS_IS' | 'HARDER' // 유사문제 난이도 EASIER - 더 쉽게 AS_IS - 그대로 HARDER - 더 어렵게

export const SIMILAR_LEVEL_KOR: Record<SimilarLevel, string> = {
  EASIER: '더 쉽게',
  AS_IS: '그대로',
  HARDER: '더 어렵게',
} as const
