export const COVER_BINDING_AREA_RECT_ID = 'COVER_BINDING_AREA'
export const COVER_BACKGROUND_ID = 'COVER_BACKGROUND'
export const COVER_BACK_DEFAULT_TEXT_MATHFLAT_PRODUCTION_ID =
  'COVER_BACK_DEFAULT_TEXT_MATHFLAT_PRODUCTION'
export const COVER_BACK_DEFAULT_TEXT_COPYRIGHT_ID = 'COVER_BACK_DEFAULT_TEXT_COPYRIGHT'

// 실제 인쇄소에서 받아온 값
export const mmToPx2 = (mm: number) => {
  return (100 / 34) * mm
}

// 실제 인쇄소에서 받아온 값
export const pxToMm2 = (px: number) => {
  return px * (34 / 100)
}

// 'rotate' 문자열에서 각도 파싱
export function parseRotateString(rotateStr) {
  // rotate 문자열에서 첫 번째 숫자(각도)를 추출
  const match = rotateStr.match(/rotate\((\d+)\s/)
  if (match) {
    const angle = parseFloat(match[1])
    return angle
  }
}

export function getTranslatedPosition(x: number, y: number, angle: number) {
  const rad = -angle * (Math.PI / 180) // 각도를 시계방향으로 변환 (음수로)

  let newX = x * Math.cos(rad) - y * Math.sin(rad)
  let newY = x * Math.sin(rad) + y * Math.cos(rad)

  // 매우 작은 값을 0으로 처리
  newX = Math.abs(newX) < 1e-10 ? 0 : newX
  newY = Math.abs(newY) < 1e-10 ? 0 : newY

  return { x: newX, y: newY }
}

export function transformString(str: string) {
  // translate(...)의 여러 패턴을 매칭
  const translateRegex = /translate\([^)]+\)/g

  // 모든 translate(...) 패턴을 추출
  const translates = str.match(translateRegex)

  if (translates && translates.length > 0) {
    // 마지막 translate(...)만 남기기
    const lastTranslate = translates[translates.length - 1]

    // translate(...) 앞의 나머지 문자열 부분 추출
    const prefix = str.replace(translateRegex, '').trim()

    // 앞의 나머지 문자열 + 마지막 translate(...) 반환
    return `${prefix} ${lastTranslate}`.trim()
  }

  // translate(...)가 없는 경우 원본 문자열 그대로 반환
  return str
}

export const PAGE_WIDTH_IN_PX = 620

export const PAGE_HEIGHT_IN_PX = 877
