import type { VisualEditorDataT } from '@mathflat/visual-editor/ui/_VisualEditor.js'
import { cloneDeep } from 'lodash'

import type { DesignTemplateResponse } from '../api'
import { COVER_DATA_TYPE, type CoverDataType } from '../constant'
import {
  COVER_BINDING_AREA_RECT_ID,
  getTranslatedPosition,
  mmToPx2,
  PAGE_HEIGHT_IN_PX,
  PAGE_WIDTH_IN_PX,
  parseRotateString,
  transformString,
} from './constant'

export class CoverData<
  PreAssignedCoverData extends Record<keyof PreAssignedCoverData, CoverDataItem | null>,
> {
  // api response는 편의를 위해 coverData에 필수값+커스텀값 다 담겨 오고,
  // client에서는 필수값과 커스텀값을 나눠서 사용하는게 편하기 때문에 여기 분리
  // 이 dto의 constructor에서 api response받아도 되고, 자기자신도 받을 수 있도록 분기
  preAssignedCoverData: PreAssignedCoverData
  customCoverData: CoverDataItem[]

  width?: number
  height?: number

  constructor(
    data:
      | DesignTemplateResponse<PreAssignedCoverData>['coverData']
      | (Omit<DesignTemplateResponse<PreAssignedCoverData>['coverData'], 'customData'> & {
          customCoverData: DesignTemplateResponse<PreAssignedCoverData>['coverData']['customData']
        })
      | CoverData<PreAssignedCoverData>,
  ) {
    if (data instanceof CoverData) {
      this.preAssignedCoverData = cloneDeep(data.preAssignedCoverData)
      this.customCoverData = cloneDeep(data.customCoverData)
      this.width = data.width
      this.height = data.height
    } else {
      const { width, height, ...requiredCoverData } = data
      const coverData = {}
      if (width) {
        this.width = Number(width)
      }
      if (height) {
        this.height = Number(height)
      }
      Object.entries(requiredCoverData).forEach(([key, value]) => {
        if (value === null) {
          coverData[key] = null
        } else {
          coverData[key] = createCoverDataItem(value)
        }
      })
      this.preAssignedCoverData =
        coverData as CoverData<PreAssignedCoverData>['preAssignedCoverData']

      const customData = 'customCoverData' in data ? data.customCoverData : data.customData

      this.customCoverData = customData.map((customCoverDataItem) =>
        createCoverDataItem(customCoverDataItem),
      )
    }
  }

  getPreAssignedItem = <K extends keyof PreAssignedCoverData>(key: K) => {
    if (!(key in this.preAssignedCoverData)) {
      throw Error('해당 키가 존재하지 않습니다.')
    }
    return this.preAssignedCoverData[key]
  }

  getCustomItem = (key: CoverDataItem['id']): CoverDataItem | undefined => {
    return this.customCoverData.find((v) => v.id === key)
  }

  getItem = <K extends keyof PreAssignedCoverData>(key: K | CoverDataItem['id']) => {
    if (key in this.preAssignedCoverData) {
      return this.getPreAssignedItem(key as K)
    }

    return this.getCustomItem(key as CoverDataItem['id'])
  }

  get allItems() {
    return toAllFilteredCoverItems({
      coverData: this.preAssignedCoverData,
      customCoverData: this.customCoverData,
    })
  }

  getPayload = () => {
    if (!this.width || !this.height) {
      throw Error('필수 값입니다.')
    }
    const preAssignedCoverDataPayload = Object.fromEntries(
      Object.entries(this.preAssignedCoverData).map(([key, value]) => {
        return [key, (value as CoverDataItem | null)?.getPayload() ?? null]
      }),
    ) as PreAssignedCoverData

    const customDataPayload = this.customCoverData.map((v) => v.getPayload()) as CoverDataItem[]

    return {
      ...preAssignedCoverDataPayload,
      customData: customDataPayload,
      width: String(this.width),
      height: String(this.height),
    }
  }

  static adjustSpineWithPageSize = ({
    coverData,
    pageSize,
  }: {
    coverData: CoverData<any>
    pageSize: number | string
  }) => {
    const maxPage = Number(pageSize)

    const sideRectWidthInMm = calculateSideRectWidthInMm(maxPage)
    const sideRectWidthInPx = mmToPx2(sideRectWidthInMm)

    const paddingInMm = (() => {
      if (maxPage < 60) return sideRectWidthInMm * 0.3
      if (maxPage < 80) return sideRectWidthInMm * 0.4
      return sideRectWidthInMm * 0.5
    })()

    const fontSize = (sideRectWidthInMm - paddingInMm) * 4
    const fontHeight = fontSize * 1.15

    const sideRectCoverDataItem = coverData.getItem(COVER_BINDING_AREA_RECT_ID)

    if (sideRectCoverDataItem && sideRectCoverDataItem.type === 'RECT') {
      sideRectCoverDataItem.props.width = sideRectWidthInPx
    }

    const sideTitleItem = coverData.getItem('sideTitle')

    // 정책 추가 : 41p 미만시 sideTitle 숨기기
    if (maxPage < 41) {
      sideTitleItem.props.hidden = true
    }

    const sideTitleWidth = parseInt(String(sideTitleItem.props.renderProps.maxWidth))

    const centerX = PAGE_WIDTH_IN_PX + sideRectWidthInPx / 2
    const centerY = PAGE_HEIGHT_IN_PX / 2 - sideRectWidthInPx / 2
    const x = centerX - sideTitleWidth / 2 - sideRectWidthInPx / 2
    const y = centerY - fontHeight / 2

    const transform = `rotate(90 ${centerX} ${centerY})`
    sideTitleItem.props.renderProps.height = fontHeight
    sideTitleItem.props.renderProps.fontSize = fontSize
    sideTitleItem.props.renderProps.x = x
    sideTitleItem.props.renderProps.y = y
    sideTitleItem.props.renderProps.transform = transform
    coverData.width = sideRectWidthInPx + PAGE_WIDTH_IN_PX * 2
    coverData.height = PAGE_HEIGHT_IN_PX

    coverData.allItems.forEach((coverDataItem) => {
      if (typeof coverDataItem === 'string' || !coverDataItem) {
        return
      }

      // 아이템이 표지 앞/등/뒤 중 어디있는 지 파악
      let x1 = 0 // 아이템의 x1 좌표
      let x2 = 0 // 아이템의 x2 좌표
      let width = 0 // 아이템의 너비
      let transform = '' // 아이템의 transform

      if (coverDataItem.type === 'TEXT' || coverDataItem.type === 'TO_LOCALE_STRING') {
        transform = coverDataItem.props.renderProps.transform ?? ''
        x1 = Number(coverDataItem.props.renderProps.x)
        width = Number(coverDataItem.props.renderProps.maxWidth)
      } else {
        transform = coverDataItem.props.transform ?? ''
        x1 = Number(coverDataItem.props.x)
        width = Number(coverDataItem.props.width)
      }

      x2 = x1 + width
      let translateX = `translate(${sideRectWidthInPx}, 0)`

      // sideTitle은 서비스 단에서 특수하게 계산되는 box로, rotate계산 로직에서 제외된다.
      if (coverDataItem.id !== 'sideTitle' && transform && transform.includes('rotate')) {
        const deg = parseRotateString(transform)
        if (deg !== undefined) {
          const { x, y } = getTranslatedPosition(sideRectWidthInPx, 0, deg)
          translateX = `translate(${x}, ${y})`
        }
      }

      if (
        x2 > PAGE_WIDTH_IN_PX + sideRectWidthInPx && // x2값이 앞면에 있다면, (아이템의 시작점인 x1값이 음수가 되며 뒷,옆면에서 시작하는 경우도 있어서 x2값을 써야함)
        coverDataItem.id !== COVER_BINDING_AREA_RECT_ID
      ) {
        const translatedTransform = transformString(
          `${transform ? transform + ' ' : ''}${translateX}`,
        ) // 가변 등 너비 만큼 우측으로 이동

        if (coverDataItem.type === 'TEXT' || coverDataItem.type === 'TO_LOCALE_STRING') {
          coverDataItem.props.renderProps.transform = translatedTransform
        } else {
          coverDataItem.props.transform = translatedTransform
        }
      }
    })

    return coverData
  }
}

function calculateSideRectWidthInMm(pages: number) {
  const a = 0.00000268 // x^2 계수
  const b = 0.0446748 // x 계수
  const c = -0.0574 // 절편
  return a * pages * pages + b * pages + c
}

function createCoverDataItem(data: CoverDataItem) {
  switch (data.type) {
    case COVER_DATA_TYPE.TEXT:
      return new TextCoverDataItem(data)
    case COVER_DATA_TYPE.IMAGE:
      return new ImageCoverDataItem(data)
    case COVER_DATA_TYPE.RECT:
      return new RectCoverDataItem(data)
    case COVER_DATA_TYPE.LINE:
      return new LineCoverDataItem(data)
    case COVER_DATA_TYPE.EDITABLE_IMAGE:
      return new EditableImageCoverDataItem(data)
    case COVER_DATA_TYPE.TO_LOCALE_STRING:
      return new ToLocaleStringCoverDataItem(data)
  }
}

// 커버데이터의 한 아이템은 이렇게 생겼습니다. 이걸 사용한 곳에서는 coverDataItem이란 느낌의 이름을 가집니다.
export type CoverDataItem<
  T extends CoverDataType = CoverDataType,
  K extends string = string,
> = T extends typeof COVER_DATA_TYPE.TEXT
  ? TextCoverDataItem<K>
  : T extends typeof COVER_DATA_TYPE.IMAGE
    ? ImageCoverDataItem<K>
    : T extends typeof COVER_DATA_TYPE.EDITABLE_IMAGE
      ? EditableImageCoverDataItem<K>
      : T extends typeof COVER_DATA_TYPE.LINE
        ? LineCoverDataItem<K>
        : T extends typeof COVER_DATA_TYPE.RECT
          ? RectCoverDataItem<K>
          : ToLocaleStringCoverDataItem<K>

export class TextCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.TEXT>
{
  type: typeof COVER_DATA_TYPE.TEXT
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.TEXT>['props']
  id: K
  order: number

  constructor(data: Omit<TextCoverDataItem<K>, 'getPayload'>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }

  getPayload() {
    return this
  }
}

export class ImageCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.IMAGE>
{
  type: typeof COVER_DATA_TYPE.IMAGE
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.IMAGE>['props']
  id: K
  order: number

  constructor(data: Omit<ImageCoverDataItem<K>, 'getPayload'>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }
  getPayload() {
    return this
  }
}
export class EditableImageCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.EDITABLE_IMAGE>
{
  type: typeof COVER_DATA_TYPE.EDITABLE_IMAGE
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.EDITABLE_IMAGE>['props']
  id: K
  order: number

  constructor(data: Omit<EditableImageCoverDataItem<K>, 'getPayload'>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }
  getPayload() {
    const {
      props: { UploadFileButton, ...restProps },
      ...rest
    } = this
    return { ...rest, props: restProps }
  }
}
export class ToLocaleStringCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.TO_LOCALE_STRING>
{
  type: typeof COVER_DATA_TYPE.TO_LOCALE_STRING
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.TO_LOCALE_STRING>['props']
  id: K
  order: number

  constructor(data: Omit<ToLocaleStringCoverDataItem<K>, 'getPayload'>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }

  getPayload() {
    return this
  }
}
export class RectCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.RECT>
{
  type: typeof COVER_DATA_TYPE.RECT
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.RECT>['props']
  id: K
  order: number

  constructor(data: Omit<RectCoverDataItem<K>, 'getPayload'>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }

  getPayload() {
    return this
  }
}

export class LineCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.LINE>
{
  type: typeof COVER_DATA_TYPE.LINE
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.LINE>['props']
  id: K
  order: number

  constructor(data: Omit<LineCoverDataItem<K>, 'getPayload'>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }

  getPayload() {
    return this
  }
}

export const toAllFilteredCoverItems = ({
  coverData,
  customCoverData,
}: {
  coverData: Record<string, CoverDataItem | null>
  customCoverData: CoverDataItem[]
}) => {
  return [...(customCoverData ?? []), Object.values(coverData ?? [])]
    .flat()
    .filter((v) => !!v) as CoverDataItem[]
}
